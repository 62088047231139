import { useEffect, useState, useContext, useRef } from "react";

import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import "grapesjs/dist/grapes.min.js";
import gjsBlockBasic from "grapesjs-blocks-basic";
import grapesJsexportPlugin from "grapesjs-plugin-export";
import "grapesjs-table";
import { Box, IconButton, Button } from "@mui/material";
import "./style.scss";
import "../HtmlPreview/style.scss";
import { styleManager } from "./utils";
import GrapesJSCustomization from "../prompts/GrapesJSCustomization";
import { intermediateSave } from "../../services/api";
import FileContext from "../../context/FileContext";
import { test } from "./test";
import {
  APPLICATION_TYPE_EDETAIL,
  APPLICATION_TYPE_EMAILER,
} from "../ImageLayout/Constants";
import { toast } from "react-toastify";
import { Exception } from "sass";

function GrapejsEditor(props) {
  const [editor, setEditor] = useState({});
  const [isPopUp, setIsPopUp] = useState(false);
  const isOptDisabled = useRef(false);
  const [popupName, setPopupName] = useState("");
  const [fileDetailsToAutoSave, setFileDetailsToAutoSave] = useState();
  const [viewRendered, setViewRendered] = useState(0);
  const fileContext = useContext(FileContext);
  const toastId = useRef(null);

  // const editorRef = useRef(null);
  let modelLocalVar = "";
  // let modelTemp = [];
  let locateExactSection = "";
  window.onbeforeunload = null;

  useEffect(() => {
    if (props.fileDetails === undefined) return;
    const editor = grapesjs.init({
      container: "#gjs",
      height: "95vh",
      width: "100%",
      forceClass: false,
      noticeOnUnload: 0,
      blockManager: {
        appendTo: "#blocks",
        blocks: [],
      },

      style: `
      body{
        overflow:scroll;
        font-family: 'Times New Roman';
        width:600px;
      }
     
      ::-webkit-scrollbar
{
    width: 0px;
}
::-webkit-scrollbar-track-piece
{
    background-color: transparent;
    -webkit-outline-radius: 0px;
}
      `,
      // selectorManager: {
      //   // custom: true,
      //   // ...
      // },
      styleManager: styleManager,
      storageManager: {
        type: "remote",
        stepsBeforeSave: 3,
        contentTypeJson: true,
        storeComponents: true,
        storeStyles: true,
        storeHtml: true,
        storeCss: true,
        id: "mycustom-",
        options: {
          remote: {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " + window.localStorage.getItem("userToken"),
            },
            fetchOptions: (opts) => {
              return { method: "POST" };
            },
            urlStore:
              process.env.REACT_APP_API_ENDPOINT +
              "/middleware/process/v2/intermediate-save",
            onStore: (data) => {
              let htmlString = exportHTML(editor);

              let body = {
                htmlContent: htmlString.htmlContent,
                upscaledContent: "", //props.upScaleImageAndFontSize(""),
                layouts: [...props.arrayOfBoxesDrawnRef.current],
                uuid: props?.fileDetails?.file_conversion_pages[0]?.page_uuid,
                id: props.fileDetails?.file_conversion_pages[0]?.id,
                parent_id:
                  props.fileDetails?.file_conversion_pages[0]?.conversion_id,
              };
              // setSaveLoading(true);
              return body;
            },
          },
        },
      },
    });

    // editor.on("canvas:dragover", (DataTransfer) => {
    //   let doc = DataTransfer;
    // });
    editor.on("load", function () {
      editor.getWrapper().set({ droppable: false });

      let fontProperty = editor.StyleManager.getProperty(
        "typography",
        "font-family"
      );
      var list = [];
      fontProperty.set("list", list);
      list = [
        fontProperty.addOption({
          value: "Helvetica Neue,Helvetica,Arial,sans-serif",
          name: "Helvetica",
        }),

        fontProperty.addOption({ value: "sans-serif", name: "sans-serif" }),

        fontProperty.addOption({ value: "Tahoma", name: "Tahoma" }),
        fontProperty.addOption({
          value: "Verdana, Geneva, sans-serif",
          name: "Verdana",
        }),
        { value: "'Times New Roman', serif", name: "Times New Roman" },
        fontProperty.addOption({
          value: "Courier New Courier, monospace",
          name: "Courier New Courier",
        }),
        fontProperty.addOption({ value: "'Lato', sans-serif", name: "Lato" }),
        fontProperty.addOption({
          value: "'Open Sans', sans-serif",
          name: "Open Sans",
        }),
        fontProperty.addOption({
          value: "'Montserrat', sans-serif",
          name: "Montserrat",
        }),
      ];
      const fontlist = editor.StyleManager.getProperty(
        "typography",
        "font-family"
      );
      fontProperty.set("list", list);
      let fontWeight = editor.StyleManager.getProperty(
        "typography",
        "font-weight"
      );
      var fonTWeight = [];
      //fontProperty.set("list", fonTWeight);
      fonTWeight = [
        fontWeight.addOption({
          value: "bold",
          name: "bold",
        }),
        fontWeight.addOption({
          value: "normal",
          name: "normal",
        }),
      ];

      fontWeight.set("list", fonTWeight);
      trackCopyPasteEvents(editor);
      editor.StyleManager.render();
    });
    editor.on("component:selected", (component) => {
      if (
        component.attributes.tagName === "table" &&
        component?.view?.el?.contentEditable
      ) {
        setTimeout(() => {
          enableContentEditing(component);
          if (viewRendered === 0) {
            component?.view?.render();
            setViewRendered((prevState) => {
              return prevState + 1;
            });
          }
        }, 10); // Small delay to e
      }

      if (component.attributes.tagName === "img") return;

      if (component.attributes.tagName === "section") {
        isOptDisabled.current = true;
        const smDim = document.getElementsByClassName(
          "gjs-sm-sector__dimension"
        )[0];
        smDim.style.display = "none";
      } else {
        isOptDisabled.current = false;
      }

      // let elem = window.getComputedStyle(component.getEl()).fontFamily;
      // component.setStyle({ "font-family": elem, ...component.getStyle() });

      // if (elem === '"Times New Roman"' || elem === "Times") {
      //   component.setStyle({
      //     "font-family": '"Times New Roman"',
      //     ...component.getStyle(),
      //   });
      // }

      // editor.Css.setRule("#" + component.getEl().id, {
      //   "font-family": elem,
      //   width: "unset",
      // });
    });

    const calcultePositions = (dimensions, obj, index) => {
      let left =
        parseFloat(obj.getStyle().left) - parseFloat(dimensions.left) + "px";
      let top =
        parseFloat(obj.getStyle().top) - parseFloat(dimensions.top) + "px";

      return { left: left, top: top };
    };

    editor.on("canvas:dragenter", (DataTransfer, result) => {
      locateExactSection = "";
      const compEle = editor.getComponents().models;
      compEle.forEach((n) => {
        if (
          //  n.attributes.tagName === "section" &&
          n.attributes.attributes.name !== props.dataRef.current
        ) {
          n.set({ droppable: false });
          let innerCompEle = n.components().models;

          innerCompEle.forEach((m) => {
            if (
              // m.attributes.tagName === "section" &&
              m.attributes.attributes.name !== props.dataRef.current
            ) {
              m.set({ droppable: false });
            } else if (
              // m.attributes.tagName === "section" &&
              m.attributes.attributes.name === props.dataRef.current
            ) {
              locateExactSection = m;
              m.set({ droppable: true });
            }
          });
        } else if (
          // n.attributes.tagName === "section" &&
          n.attributes.attributes.name === props.dataRef.current
        ) {
          n.set({ droppable: true });
          locateExactSection = n;
        }
      });
    });

    editor.on("canvas:dragend", (DataTransfer, model) => {
      // modelLocalVar[0].addStyle({ "background-color": "red" });
      let dimensions = "";
      if (modelLocalVar === "") return;
      const compEle = editor.getComponents().models;
      //  dimensions = locateExactSection.getStyle();
      var num = parseInt(props.dataRef.current.replace(/[^0-9]/g, ""));
      const canvasElem = props.arrayOfBoxesDrawnRef.current[num];

      dimensions = canvasElem;

      if (modelLocalVar !== "")
        modelLocalVar.addStyle(calcultePositions(dimensions, modelLocalVar));

      if (locateExactSection !== "")
        locateExactSection.set({ droppable: false });
      props.setData("");
      modelLocalVar = "";
    });

    editor.on("canvas:drop", (DataTransfer, model) => {
      modelLocalVar = model;

      // if (Array.isArray(model)) {
      //   modelTemp = model?.map((obj) => obj.getStyle());
      // }
    });

    editor.on("component:add", (component) => {
      if (component.get("tagName") === "table") {
        if (component.get("type") !== "table") {
          component.set({ type: "table" });
          component.addAttributes({ "data-gjs-table": "table" });
        }

        setTimeout(() => {
          enableContentEditing(component);
          if (viewRendered === 0) {
            component?.view?.render();
            setViewRendered((prevState) => {
              return prevState + 1;
            });
          }
        }, 10); // Small delay to ensure component is added before re-render
      }
    });

    function enableContentEditing(component) {
      component.set({ editable: true });
      if (!component?.view?.el) return;
      component.view.el.contentEditable = "true";
      component.view.el.focus();
      // Disable editing when done (on blur)
      component.view.el.addEventListener("blur", () => {
        component.set({ editable: false });
        component.view.el.contentEditable = "false";
      });
    }

    setEditor(editor);
    props.usrGenHtMLRef.current = editor;

    return () => editor.destroy();
  }, [typeof props.fileDetails]); // for autosave changed this component did mount to component didupdate.
  // this use effect runs only when the filedetails changes from undefined to object on refresh for the first time.

  const trackCopyPasteEvents = (editor) => {
    const iframe = editor.Canvas?.getFrameEl(); // Get the iframe element
    if (iframe) {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document; // Get iframe's document
      iframeDoc.addEventListener("paste", (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData("text");
        e.target.ownerDocument.execCommand("insertText", false, text);
      });
    }
  };

  const intermediateSaveCall = (origin) => {
    const htmlString = exportHTML(editor, "wrapper");

    let body = {
      htmlContent: htmlString.htmlContent,
      upscaledContent:
        origin === "useEffect"
          ? props.upScaleImageAndFontSize(htmlString.htmlContentWithWrapper)
          : "",

      layouts: [...props.arrayOfBoxesDrawnRef.current],
      uuid: props?.fileDetails?.file_conversion_pages[0]?.page_uuid,
      id: props.fileDetails?.file_conversion_pages[0]?.id,
      parent_id: props.fileDetails?.file_conversion_pages[0]?.conversion_id,
    };
    intermediateSave(body)
      .then((response) => {
        if (origin === "useEffect") {
          props.setUploadedFileDetails(response.data);
          props.setActiveStep(3);
          props.setUpscaledHTMLUsrGnrtdPath(
            response.data.file_conversion_pages[0].scaled_html_file_path
          );
          props.setIsGJSLoading(false);
          props.getUserGeneratedHTML(htmlString);
        }
      })
      .catch((error) => {
        props.setIsGJSLoading(false);
      });
  };
  useEffect(() => {
    if (props.exportCount > 0) {
      intermediateSaveCall("useEffect"); //on click of next button
    }
  }, [props.exportCount]);

  useEffect(() => {
    if (props.genLayoutRef.current === true) {
      // && Object.keys(editor).length) {
      window.localStorage.removeItem("gjsProject");
      setViewRendered(0);
      generateLayout();
    }
  }, [props.genLayoutRef.current]);
  const findHiphen = (yellowDiv) => {
    if (yellowDiv?.childNodes?.length > 1) {
      for (let i = 0; i < yellowDiv.childNodes.length; i++) {
        if (
          yellowDiv.childNodes[i]?.textContent?.includes("-") ||
          yellowDiv.childNodes[i]?.textContent?.includes("_")
        )
          return i;
      }
    }
  };
  const getColumnsInsideYellowDiv = (yellowDiv, origin) => {
    let columns = 1;
    if (yellowDiv?.childNodes?.length > 1) {
      for (let j = 1; j < yellowDiv?.childNodes?.length; j++) {
        if (
          yellowDiv.childNodes[0]?.textContent.length > 2 && //dot of list
          (70 *
            (parseFloat(yellowDiv.childNodes[0].getAttribute("lineHeight")) +
              parseFloat(yellowDiv.childNodes[0].style.top))) /
            100 >
            parseFloat(yellowDiv.childNodes[j].style.top)
        ) {
          columns++;
        } else return columns;
      }
    }
    return columns;
  };
  const getHeighestLineWidthDiv = (yellowDiv) => {
    var longestline = {
      width: parseFloat(yellowDiv?.childNodes[0]?.getAttribute("linewidth")),
      fontSize: parseFloat(yellowDiv?.childNodes[0]?.style?.fontSize),
      left: parseFloat(yellowDiv?.childNodes[0]?.style?.left),
      content: yellowDiv?.childNodes[0]?.textContent,
    };

    for (let i = 0; i < yellowDiv.childNodes.length; i++) {
      let currentLineWidth = parseFloat(
        yellowDiv.childNodes[i].getAttribute("linewidth")
      );
      if (currentLineWidth > longestline.width) {
        longestline.width = currentLineWidth;
        longestline.fontSize = parseFloat(
          yellowDiv?.childNodes[i]?.style?.fontSize
        );
      }
    }
    return longestline;
  };
  const getrelativeFontSize = (longContentLine, yellowDiv) => {
    return (
      (parseFloat(yellowDiv.getAttribute("originalBoxWidth")) *
        longContentLine.fontSize) /
      (longContentLine.width + longContentLine.left)
    );
    // return (
    //   ((parseFloat(yellowDiv.getAttribute("originalBoxWidth")) +
    //     parseFloat(yellowDiv.style.left)) *
    //     longContentLine.fontSize) /
    //   (longContentLine.width + longContentLine.left)
    // );
  };
  const getBiggestFontSize = (yellowDiv) => {
    let font_size = 0;
    let htmlContentLines = yellowDiv.childNodes;
    for (let i = 0; i < yellowDiv.childNodes.length; i++) {
      if (htmlContentLines[i].tagName !== "IMG") {
        if (parseFloat(htmlContentLines[i].style.fontSize) > font_size)
          font_size = parseFloat(htmlContentLines[i].style.fontSize);
      }
    }
    return font_size;
  };
  const getRightAlignedPercentage = (yellowBox, content) => {
    //if (content.textContent.includes("userEmailAddress")) ;
    let expectedlLeft =
      parseFloat(yellowBox.style.left) + parseFloat(yellowBox.style.width);
    let actualLeft = parseFloat(content.style.left);
    let contentlRight =
      parseFloat(content.style.left) +
      parseFloat(content.getAttribute("linewidth"));
    let rightGap = (contentlRight * 100) / expectedlLeft;
    return {
      leftGap: (actualLeft * 100) / expectedlLeft,
      rightGap: 100 - rightGap,
    };
  };
  const contentExceedsParentDiv = (
    yellowDiv,
    contentSFontSize,
    newFontSize,
    originalWidth,
    index
  ) => {
    let newWidth =
      (parseFloat(originalWidth) * parseFloat(newFontSize)) /
      parseFloat(contentSFontSize);

    let contentSRight =
      parseFloat(yellowDiv.style.left) +
      parseFloat(yellowDiv.childNodes[index].style.left) +
      newWidth;
    let yellowDivsRight =
      parseFloat(yellowDiv.style.left) + parseFloat(yellowDiv.style.width);

    if (contentSRight > yellowDivsRight && contentSRight - yellowDivsRight < 50)
      return newWidth + 2;
    else return false;
  };
  const addNewFontSiwwwze = (relativeFontSize, yellowDiv) => {
    if (yellowDiv.getAttribute("fontsizecorrected") === "true") return;
    for (let i = 0; i < yellowDiv.childNodes.length; i++) {
      // if (yellowDiv.childNodes[i].innerHTML.includes("OFINIVA")) ;
      // if (
      //   relativeFontSize - parseInt(yellowDiv.childNodes[i].style.fontSize) >
      //   (parseInt(yellowDiv.childNodes[i].style.fontSize) * 30) / 100
      // ) {
      //   relativeFontSize =
      //     parseInt(yellowDiv.childNodes[i].style.fontSize) +
      //     (parseInt(yellowDiv.childNodes[i].style.fontSize) * 30) / 100;
      // }
      let rightAlignedPercentage = getRightAlignedPercentage(
        yellowDiv,
        yellowDiv.childNodes[i]
      );
      let tempRelativeFontSize = "";

      let lhsFontSize = parseFloat(yellowDiv.childNodes[i].style.fontSize);
      if (rightAlignedPercentage.rightGap < 2)
        tempRelativeFontSize = lhsFontSize;
      else if (rightAlignedPercentage.leftGap > 20)
        tempRelativeFontSize =
          lhsFontSize + lhsFontSize * (rightAlignedPercentage.rightGap / 100);
      yellowDiv.childNodes[i].style.fontSize =
        (tempRelativeFontSize ? tempRelativeFontSize : relativeFontSize) + "px";

      let newContainerWidth = contentExceedsParentDiv(
        yellowDiv,
        lhsFontSize,
        tempRelativeFontSize || relativeFontSize,
        yellowDiv.childNodes[i].getAttribute("linewidth"),
        i
      );

      if (newContainerWidth) {
        yellowDiv.style.width =
          parseFloat(yellowDiv.childNodes[i].style.left) +
          newContainerWidth +
          "px";
      }

      yellowDiv.childNodes[i].setAttribute("fontsizecorrected", true);
    }
    yellowDiv.setAttribute("fontsizecorrected", true);
  };
  const addBigFontSize = (relativeFontSize, yellowDiv) => {
    if (yellowDiv.getAttribute("fontsizecorrected") === "true") return;
    for (let i = 0; i < yellowDiv.childNodes.length; i++) {
      let lhsFontSize = parseFloat(yellowDiv.childNodes[i].style.fontSize);

      yellowDiv.childNodes[i].style.fontSize = relativeFontSize;

      let newContainerWidth = contentExceedsParentDiv(
        yellowDiv,
        lhsFontSize,
        relativeFontSize,
        yellowDiv.childNodes[i].getAttribute("linewidth"),
        i
      );

      if (newContainerWidth) {
        yellowDiv.style.width =
          parseFloat(yellowDiv.childNodes[i].style.left) +
          newContainerWidth +
          "px";
      }
      yellowDiv.childNodes[i].setAttribute("fontsizecorrected", true);
    }
    yellowDiv.setAttribute("fontsizecorrected", true);
  };
  // const getGreenDivs = () => {
  //   var body = document.createElement("body");
  //   for (let i = 0; i <= 2; i++) {
  //     var section = document.createElement(["section"]);
  //     // let top="0px";
  //     if (i > 0) {
  //       // top= currentTop- body.childNodes[i-1].top+height
  //       // left= currentLeft- body.childNodes[i-1].left+width
  //     }
  //     section.style = `top:${
  //       10 * (i * 10)
  //     }px; left:${"10px"}; width:${"100px"}; height:${"50px"}; z-index:${999}; ${"position:absolute"} ; ${"background:green"}`;
  //     body.appendChild(section);
  //   }
  //   return body.innerHTML;
  // };
  const getPositionRelative = () => {};
  const getDomElementFromObject = (
    families,
    cloneSections,
    body,
    childSec,
    parentSction
  ) => {
    if (childSec !== "childSec") var body = document.createElement("body");
    let division = childSec !== "childSec" ? "section" : "div";
    // let width = "min-width";
    families.forEach((family, index) => {
      /********PAI********* */
      if (fileContext.file.application_type === APPLICATION_TYPE_EDETAIL)
        if (division === "section") {
          //PAI
          // width = "width";
          if (index === 0) family.marginTop = family.top;
          else
            family.marginTop =
              parseFloat(family.top) -
              parseFloat(families[index - 1].bottom) +
              "px";
          delete family.position;

          //PAI
        } else {
          debugger;
          if (family.column === "multi") {
          }
        }
      /********PAI********* */

      var section = document.createElement([division]);
      if (fileContext.file.application_type === APPLICATION_TYPE_EDETAIL) {
        section.style = `top:${family.containerAbTop || family.top}; left:${
          family.containerAbLeft || family.left
        };width:${
          division === "section" ? family.width : "fit-content"
        }; min-width:${division === "div" ? family.width : ""};min-height:${
          family.height
        }; z-index:${family.zIndex}; position:${family.position}; display:${
          family.display
        };flex-direction : ${family.flexDirection};align-items:${
          family.alignItems
        };justify-content:${family.justifyContent};margin-top:${
          family.marginTop
        };margin-bottom:${family.marginBottom}; margin-left:${
          family.marginLeft
        }; margin-right:${family.marginRight}; padding-left:${
          family.paddingLeft
        };flex-wrap:${family.flexWrap};  background-image: ${
          family.backgroundImage
        }; background-size:${family.backgroundSize};`;
      } else {
        section.style = `top:${family.top}; left:${family.left}; width:${family.width}; height:${family.height}; z-index:${family.zIndex}; position:${family.position}; `;
      }
      // }; ${"position:absolute"} ;`;
      section.setAttribute("draggable", false);
      section.setAttribute("name", family.name);
      section.setAttribute("child", "inner");
      section.setAttribute("coulumn", family?.column);
      section.setAttribute("originalBoxWidth", family?.originalBoxWidth);
      section.setAttribute("blocktype", family?.blocktype);

      section.setAttribute("coulumn", family?.column);

      family.htmlContent?.forEach((content) => {
        if (content !== "")
          section.appendChild(HtMLStringToDOMElement(content, family));
      });
      if (childSec === "childSec") {
        // getSectionsNewPosns(section, parentSction);
        parentSction.appendChild(section);
        // body.appendChild(parentSction);
        // parentSction = "";
        // childSec = "";
      } else {
        body.appendChild(section);
      }
      if (family?.children?.length) {
        let childSec = "childSec";
        getDomElementFromObject(
          family.children,
          cloneSections,
          body,
          childSec,
          section
        );
        childSec = "";
      }
    });

    if (
      body.childNodes.length ===
      props.arrayOfBoxesDrawnRef.current.filter((box) =>
        box?.outline?.includes("#ef9a9a")
      ).length
    )
      setBackgroundColor(body);
    //font Size correction////////////////////////////////////////////////////////////////
    if (fileContext.file.application_type === APPLICATION_TYPE_EDETAIL) {
      /*let yellowDivs = body.childNodes;
      for (let i = 0; i < yellowDivs.length; i++) {
        if (!yellowDivs[i].childNodes?.length) continue;
        let columnsInsideYellowDiv = getColumnsInsideYellowDiv(yellowDivs[i]);
        // let longContentLine = getHeighestLineWidthDiv(yellowDivs[i]);
        // let relativeFontSize = getrelativeFontSize(
        //   longContentLine,
        //   yellowDivs[i]
        // );
        let relativeFontSize = getBiggestFontSize(yellowDivs[i]);

        // if (columnsInsideYellowDiv > 1)
        //   relativeFontSize /=
        //     columnsInsideYellowDiv - columnsInsideYellowDiv * 0.2;
        // relativeFontSize = Math.round(relativeFontSize * 10) / 10;

        addBigFontSize(relativeFontSize, yellowDivs[i]);
      }*/
    } else if (
      cloneSections[body.childNodes.length - 1]?.children?.length ===
        body.lastChild.childNodes.length &&
      body.lastChild.childNodes.length === families.length
    ) {
      // if (body.childNodes.length === 2) ;
      let yellowDivs = body.lastChild.childNodes;

      for (let i = 0; i < yellowDivs.length; i++) {
        if (yellowDivs[i]?.childNodes?.[0]?.tagName === "IMG") continue;
        if (
          yellowDivs[i].getAttribute("coulumn") === "multi" || // columns
          yellowDivs[i]?.childNodes[0]?.getAttribute("child") === "inner"
        ) {
          let yellowDivsInGreen = yellowDivs[i].childNodes;
          for (let j = 0; j < yellowDivsInGreen.length; j++) {
            if (yellowDivsInGreen[j].getAttribute("child") !== "inner")
              continue;

            // let longContentLine = getHeighestLineWidthDiv(yellowDivsInGreen[j]);

            // let columnsInsideYellowDiv = getColumnsInsideYellowDiv(
            //   yellowDivsInGreen[j],
            //   "insideGreen"
            // );commentedforBiggestFontSizeLogic
            let relativeFontSize = getBiggestFontSize(yellowDivsInGreen[j]);
            // let relativeFontSize = getrelativeFontSize(
            //   longContentLine,
            //   yellowDivsInGreen[j]
            // );

            //   // else relativeFontSize = Math.floor(relativeFontSize);
            // }

            // if (columnsInsideYellowDiv > 1) {
            //   relativeFontSize /= columnsInsideYellowDiv;
            // }//commentedforBiggestFontSizeLogic
            relativeFontSize = Math.round(relativeFontSize * 10) / 10;
            addBigFontSize(relativeFontSize, yellowDivsInGreen[j]);
          }
          // greenDivs = yellowDivs;
          // yellowDivs = greenDivs[i].childNodes;
          // i--;
          // continue;
        } else {
          //if thee text crosses the yellow bounding box, ignore the text
          if (
            yellowDivs[i].getAttribute("child") !== "inner" ||
            !yellowDivs[i].childNodes?.length
          )
            continue;
          // let columnsInsideYellowDiv = getColumnsInsideYellowDiv(yellowDivs[i]);

          // let longContentLine = getHeighestLineWidthDiv(yellowDivs[i]);
          let relativeFontSize = getBiggestFontSize(yellowDivs[i]);

          // let relativeFontSize = getrelativeFontSize(
          //   longContentLine,
          //   yellowDivs[i]
          // );

          //   // else relativeFontSize = Math.floor(relativeFontSize);
          // }

          // if (columnsInsideYellowDiv > 1)
          //   relativeFontSize /=
          //     columnsInsideYellowDiv - columnsInsideYellowDiv * 0.2;
          relativeFontSize = Math.round(relativeFontSize * 10) / 10;

          addBigFontSize(relativeFontSize, yellowDivs[i]);
        }
      }
    }
    // {
    //   console.log("inside first big if");
    //   // if (body.childNodes.length === 2) ;
    //   let yellowDivs = body.childNodes;
    //   for (let i = 0; i < yellowDivs.length; i++) {
    //     if (yellowDivs[i].getAttribute("blocktype") === "LAYOUT_LIST") {
    //       let yellowDivsInGreen = yellowDivs[i].childNodes;
    //       for (let j = 0; j < yellowDivsInGreen.length; j++) {
    //         console.log("inside first big if, ifor, jfor");
    //         if (yellowDivsInGreen[j].getAttribute("child") !== "inner")
    //           continue;
    //         console.log(
    //           yellowDivsInGreen[j].childNodes[1]?.textContent,
    //           "textContenttt"
    //         );

    //         let longContentLine = getHeighestLineWidthDiv(yellowDivsInGreen[j]);
    //         let columnsInsideYellowDiv = getColumnsInsideYellowDiv(
    //           yellowDivsInGreen[j],
    //           "insideGreen"
    //         );
    //         let divHashiphen = findHiphen(yellowDivsInGreen[j]);
    //         let relativeFontSize = getrelativeFontSize(
    //           longContentLine,
    //           yellowDivsInGreen[j]
    //         );
    //         if (divHashiphen !== undefined) {
    //           // if (relativeFontSize / Math.floor(relativeFontSize) >= 1.03)
    //           relativeFontSize -= 0.7;
    //           // else relativeFontSize = Math.floor(relativeFontSize);
    //         }

    //         if (columnsInsideYellowDiv > 1) {
    //           relativeFontSize /= columnsInsideYellowDiv;
    //         }
    //         relativeFontSize = Math.round(relativeFontSize * 10) / 10;
    //         addBigFontSize(relativeFontSize, yellowDivsInGreen[j]);
    //       }
    //       // greenDivs = yellowDivs;
    //       // yellowDivs = greenDivs[i].childNodes;
    //       // i--;
    //       // continue;
    //     } else {
    //       //if thee text crosses the yellow bounding box, ignore the text
    //       if (
    //         yellowDivs[i].getAttribute("child") !== "inner" ||
    //         !yellowDivs[i].childNodes?.length
    //       )
    //         continue;
    //       let columnsInsideYellowDiv = getColumnsInsideYellowDiv(yellowDivs[i]);
    //       console.log(
    //         yellowDivs[i].childNodes[1]?.textContent,
    //         "textContenttt"
    //       );
    //       let divHashiphen = findHiphen(yellowDivs[i]);
    //       let longContentLine = getHeighestLineWidthDiv(yellowDivs[i]);
    //       let relativeFontSize = getrelativeFontSize(
    //         longContentLine,
    //         yellowDivs[i]
    //       );
    //       if (divHashiphen !== undefined) {
    //         // if (relativeFontSize / Math.floor(relativeFontSize) >= 1.03)
    //         relativeFontSize -= 0.7;
    //         // else relativeFontSize = Math.floor(relativeFontSize);
    //       }

    //       if (columnsInsideYellowDiv > 1)
    //         relativeFontSize /=
    //           columnsInsideYellowDiv - columnsInsideYellowDiv * 0.2;
    //       console.log("addingFontSize relativeFontSize", relativeFontSize);
    //       relativeFontSize = Math.round(relativeFontSize * 10) / 10;

    //       addBigFontSize(relativeFontSize, yellowDivs[i]);
    //     }
    //   }
    // }
    return body.innerHTML;
  };
  function setBackgroundColor(body) {
    var childNode = body.childNodes;

    Array.from(childNode).forEach((child, index) => {
      let x = "";
      let ChildColor = "";
      Array.from(child.childNodes).forEach((node) => {
        if (node.getAttribute("blocktype") === "YOLO_CTA") {
          return;
        }
        if (node?.style?.backgroundColor?.length > 3) {
          x = node?.style?.backgroundColor;
          child.style.backgroundColor = x;
        }
        if (x === "") {
          Array.from(node.childNodes).forEach((grandChild) => {
            if (grandChild?.style?.backgroundColor?.length > 3) {
              ChildColor = grandChild.style.backgroundColor;
              child.style.backgroundColor = ChildColor;
            }

            if (ChildColor === "") {
              Array.from(grandChild.childNodes).forEach((greatChild) => {
                if (greatChild.style?.backgroundColor.length > 3) {
                  ChildColor = greatChild.style.backgroundColor;
                  child.style.backgroundColor = ChildColor;
                }
              });
            }
          });
        }
      });
    });
  }

  // function buildHierarchy(objects, origin) {
  //   for (let i = 0; i < objects.length; i++) {
  //     const parent = objects[i];
  //     parent.children = [];
  //     for (
  //       let j = i + 1;
  //       j < objects.length &&
  //       objects.length > 0 &&
  //       !objects[i + 1].outline.includes("#ef9a9a");
  //       j++
  //     ) {
  //       // if (i !== j) {
  //       const child = objects[j];
  //       if (
  //         parseFloat(child.left) >= parseFloat(parent.left) - 5 &&
  //         parseFloat(child.left) + parseFloat(child.width) <=
  //           parseFloat(parent.left) + parseFloat(parent.width) + 5 &&
  //         parseFloat(child.top) >= parseFloat(parent.top) - 5 &&
  //         parseFloat(child.top) + parseFloat(child.height) <=
  //           parseFloat(parent.top) + parseFloat(parent.height) + 5
  //       ) {
  //         if (origin != "fromChildren") {
  //           if (child.blocktype === "YOLO_LINES");
  //           child.initTop = child.top;
  //           //important to have top of initial top
  //           child.initLeft = child.left;
  //           child.initBottom = child.bottom;

  //           child.initRight = child.right;
  //         }
  //         // if (child.blocktype !== "LAYOUT_FIGURE") {
  //         child.top = parseFloat(child.top) - parseFloat(parent.top) + "px";
  //         child.left = parseFloat(child.left) - parseFloat(parent.left) + "px";
  //         child.LPWidth =
  //           parseFloat(child.left) + parseFloat(child.width) + "px";
  //         child.TPHeight =
  //           parseFloat(parent.bottom) + parseFloat(child.bottom) + "px";
  //         // }

  //         child.parent = parent.column;
  //         child.parentBlocktype = parent.blocktype;

  //         // push only layouts inside layout only when,
  //         // 1. parent layout is red
  //         // 2. when parent layout is green
  //         // 3. parent is LIST
  //         // 4. parent is not LAYOUT_FIGURE

  //         // if (parent.blocktype !== "LAYOUT_FIGURE") {
  //         //   if (parent.blocktype === undefined) parent.children.push(child);
  //         //   else if (parent.blocktype === "LAYOUT_LIST") {
  //         //     parent.children.push(child);
  //         //   }
  //         // }

  //         //if check to avoid text layouts inside LAYOUT_FIGURE"

  //         if (
  //           parent.blocktype !== "LAYOUT_FIGURE" &&
  //           parent.blocktype !== "LAYOUT_TABLE"
  //         )
  //           parent.children.push(child);

  //         objects.splice(j, 1); //
  //         if (j > 0) j--;
  //       }
  //       // }
  //     }
  //     // If the parent has children, recursively call the function for them    if (parent.children.length > 0) {
  //     if (parent.children.length > 1) {
  //       buildHierarchy(parent.children, "fromChildren");
  //     }
  //   }
  // }

  // function buildInnerContentHierarchy(objects, origin) {
  //   for (let i = 0; i < objects.length; i++) {
  //     const parent = objects[i];
  //     parent.children = [];
  //     const [parentLPW, parentTpH] = getLpWTpH(parent, false);

  //     for (let j = i + 1; j < objects.length && objects.length > 0; j++) {
  //       const child = objects[j];
  //       const [childLpW, childTpH] = getLpWTpH(child, false);

  //       if (
  //         parseFloat(child.left) >= parseFloat(parent.left) - 5 &&
  //         childLpW <= parentLPW + 5 &&
  //         parseFloat(child.top) >= parseFloat(parent.top) - 5 &&
  //         childTpH <= parentTpH + 5
  //       ) {
  //         if (origin != "fromChildren") {
  //           child.initTop = child.top;
  //           child.initLeft = child.left;
  //           child.initBottom = child.bottom;
  //           child.initRight = child.right;
  //         }

  //         child.top = parseFloat(child.top) - parseFloat(parent.top) + "px";
  //         child.left = parseFloat(child.left) - parseFloat(parent.left) + "px";
  //         child.LPWidth =
  //           parseFloat(child.left) + parseFloat(child.width) + "px";
  //         child.TPHeight =
  //           parseFloat(parent.bottom) + parseFloat(child.bottom) + "px";

  //         child.parent = parent.column;
  //         child.parentBlocktype = parent.blocktype;

  //         if (
  //           parent.blocktype !== "LAYOUT_FIGURE" &&
  //           parent.blocktype !== "LAYOUT_TABLE"
  //         )
  //           parent.children.push(child);

  //         objects.splice(j, 1); //
  //         if (j > 0) j--;
  //       }
  //     }
  //     if (parent.children.length > 1) {
  //       buildInnerContentHierarchy(parent.children, "fromChildren");
  //     }
  //   }
  // }

  const buildInnerContentHierarchy = (objects) => {
    const blockTypes = ["LAYOUT_FIGURE", "LAYOUT_TABLE", "LAYOUT_LIST"];
    const rootElements = objects.filter((item) =>
      blockTypes.includes(item.blocktype)
    );
    objects = objects.filter((item) => !blockTypes.includes(item.blocktype));

    for (let i = 0; i < rootElements.length; i++) {
      const parent = rootElements[i];
      parent.children = [];
      const [parentLpW, parentTpH] = getLpWTpH(parent, false);

      for (let j = 0; j < objects.length; j++) {
        const child = objects[j];
        const [childLpW, childTpH] = getLpWTpH(child, false);
        if (
          parseFloat(child.left) >= parseFloat(parent.left) - 5 &&
          childLpW <= parentLpW + 5 &&
          parseFloat(child.top) >= parseFloat(parent.top) - 5 &&
          childTpH <= parentTpH + 5
        ) {
          child.initTop = child.top;
          child.initLeft = child.left;
          child.initBottom = child.bottom;
          child.initRight = child.right;

          child.top = parseFloat(child.top) - parseFloat(parent.top) + "px";
          child.left = parseFloat(child.left) - parseFloat(parent.left) + "px";
          child.LPWidth = childLpW + "px";
          child.TPHeight = childTpH + "px";

          child.parent = parent.column;
          child.parentBlocktype = parent.blocktype;

          if (
            parent.blocktype !== "LAYOUT_FIGURE" &&
            parent.blocktype !== "LAYOUT_TABLE"
          ) {
            parent.children.push(child);
          }
          objects.splice(j, 1);
          if (j > -1) j--;
        }
      }
    }
    objects = objects
      .concat(rootElements)
      .sort((a, b) => parseFloat(a.top) - parseFloat(b.top));

    return objects;
  };

  // get left plus width and top plus height of element
  const getLpWTpH = (element, isColumn) => {
    const lpw =
      parseFloat(isColumn ? element.initLeft : element.left) +
      parseFloat(element.width);
    const tph =
      parseFloat(isColumn ? element.initTop : element.top) +
      parseFloat(element.height);
    return [lpw, tph];
  };

  const updateElementStyle = (child, parent, childLpW, isColumn) => {
    child.parent = parent.column;
    child.parentBlocktype = parent.blocktype;
    child.initTop = child.top;
    child.initLeft = child.left;
    child.initBottom = child.bottom;
    child.initRight = child.right;

    child.top =
      parseFloat(child.top) -
      parseFloat(isColumn ? parent.initTop : parent.top) +
      "px";
    child.left =
      parseFloat(child.left) -
      parseFloat(isColumn ? parent.initLeft : parent.left) +
      "px";
    child.LPWidth = childLpW + "px";
    child.TPHeight =
      parseFloat(parent.bottom) + parseFloat(child.bottom) + "px";
  };

  function buildHierarchy(allSections) {
    let { outerSection, greenColumn, innerLayout } = allSections;
    for (let i = 0; i < outerSection.length; i++) {
      const parent = outerSection[i];
      parent.children = [];
      const [sectionLpW, sectionTpH] = getLpWTpH(parent, false);

      for (let j = 0; j < greenColumn.length; j++) {
        const column = greenColumn[j];
        column.children = [];
        const [colLpW, colTpH] = getLpWTpH(column, false);
        if (sectionTpH + 5 > colTpH && sectionLpW + 5 > colLpW) {
          updateElementStyle(column, parent, colLpW, false);
          parent.children.push(column);
          greenColumn.splice(j, 1);
          if (j > -1) j--;
        }
      }

      if (parent.children.length > 0) {
        parent.children.sort(
          (a, b) => parseFloat(a.initLeft) - parseFloat(b.initLeft)
        );
        for (let c = 0; c < parent.children.length; c++) {
          const column = parent.children[c];
          const [colLpW, colTpH] = getLpWTpH(column, true);

          for (let k = 0; k < innerLayout.length; k++) {
            const inner = innerLayout[k];
            const [innerLpW, innerTpH] = getLpWTpH(inner, false);
            if (colTpH + 5 > innerTpH && colLpW + 5 > innerLpW) {
              updateElementStyle(inner, column, innerLpW, true);
              column.children.push(inner);
              innerLayout.splice(k, 1);
              if (k > -1) k--;
            }
          }
        }
      } else {
        for (let k = 0; k < innerLayout.length; k++) {
          const inner = innerLayout[k];
          const [innerLpW, innerTpH] = getLpWTpH(inner, false);
          if (sectionTpH + 5 > innerTpH && sectionLpW + 5 > innerLpW) {
            updateElementStyle(inner, parent, innerLpW, false);
            parent.children.push(inner);
            innerLayout.splice(k, 1);
            if (k > -1) k--;
          }
        }
      }
    }
    return outerSection;
  }

  let collectClass = "";
  const canBePushed = (blocks, section, parentSection) => {
    // parentSection.children.sort((a, b) =>parseFloat(a.top)-parseFloat(b.top));
    let blockIndex = [];
    for (let i = 0; i < blocks.length && blocks.length > 0; i++) {
      section.height =
        parseFloat(section?.initBottom) - parseFloat(section?.initTop) + "px";
      let parentLeft =
        parseFloat(section?.initLeft) || parseFloat(section.left);
      let parentTop = parseFloat(section?.initTop) || parseFloat(section.top);
      let parentHeight = parseFloat(section.height);
      let parentWidth = parseFloat(section.width);
      // if (!blocks[i]?.style?.width) {
      //   let contentWidth = parseFloat(
      //     blocks[i].getClientRects()[0].width.toFixed(2)
      //   );
      //   blocks[i].style.width = contentWidth + "px";
      // }
      let contentString = "";
      if (
        parentLeft - 5 <= parseFloat(blocks[i].style.left) &&
        parentLeft + parentWidth + 5 >=
          parseFloat(blocks[i].style.left) +
            parseFloat(blocks[i].getClientRects()[0].width) &&
        parentTop - 5 <= parseFloat(blocks[i].style.top) &&
        parentTop + parentHeight + 5 >=
          parseFloat(blocks[i].style.top) +
            parseFloat(blocks[i].getClientRects()[0].height)
      ) {
        if (fileContext.file.application_type !== APPLICATION_TYPE_EDETAIL) {
          blocks[i].style.nTop =
            parseFloat(blocks[i].style.top) - parentTop + "px";
          blocks[i].style.nLeft =
            parseFloat(blocks[i].style.left) - parentLeft + "px";
        }

        blocks[i].setAttribute(
          "lineWidth",
          blocks[i].getBoundingClientRect().width
        );
        blocks[i].setAttribute(
          "lineHeight",
          parseFloat(blocks[i].getBoundingClientRect().height)
        );
        if (fileContext.file.application_type === APPLICATION_TYPE_EDETAIL) {
          if (blocks[i].outerHTML.startsWith("<div")) {
            contentString = blocks[i].outerHTML.replace(
              /position\s*:\s*absolute\s*;?/i,
              ""
            );
            blocks[i].style.wordWrap = "break-word";
            blocks[i].style.whiteSpace = "normal";
            section.alignItems = blocks[i].style.textAlign;
            if (blocks[i].style.textAlign === "left")
              section.paddingLeft = "3px";
            else if (blocks[i].style.textAlign === "right")
              section.paddingRight = "3px";
          } else if (
            blocks[i].outerHTML.startsWith("<img") ||
            blocks[i].outerHTML.startsWith("<table")
          ) {
            contentString = blocks[i].outerHTML.replace(
              /position\s*:\s*absolute\s*;?/i,
              ""
            );
          } else contentString = blocks[i].outerHTML;
          if (section.blocktype === "LAYOUT_FIGURE_WITH_TEXT") {
            if (contentString.startsWith("<div")) {
              let layoutImgTxt = {
                top:
                  parseFloat(blocks[i].style.top) -
                  parseFloat(section.initTop) +
                  "px",
                left:
                  parseFloat(blocks[i].style.left) -
                  parseFloat(section.initLeft) +
                  "px",
                width: blocks[i].getAttribute("linewidth"),
                height: blocks[i].getAttribute("lineheight"),
                bottom:
                  parseFloat(blocks[i].style.top) +
                  parseFloat(blocks[i].getAttribute("lineheight")) +
                  "px",
                right:
                  parseFloat(blocks[i].style.left) +
                  parseFloat(blocks[i].getAttribute("linewidth")) +
                  "px",
                blocktype: "TEXT",
                htmlContent: [contentString],
              };
              if (!section?.children) section.children = [];
              section.children = [...section.children, layoutImgTxt];
            } else if (contentString.startsWith("<img")) {
              section.backgroundImage = `url(${blocks[i].src})`;
              section.backgroundSize =
                "cover"; /* Makes the image cover the entire div */
              section.backgroundPosition = "center";
              contentString = "";
            }
          }
        } else contentString = blocks[i].outerHTML;

        blockIndex = [...blockIndex, contentString];

        blocks.splice(i, 1);
        i--;
      }
    }
    // blockIndex = blockIndex.replace(/position:\s*absolute;\s*/i, "");
    return blockIndex;
  };

  const pushContentsInside = (blocks, sections, parentSection) => {
    let nthBlock = "";

    let blocksPushed = [];
    for (let i = 0; i < sections.length; i++) {
      if (sections[i]?.children?.length) {
        pushContentsInside(blocks, sections[i].children, sections[i]);
      } else {
        if (sections[i]?.outline?.includes("#00A5D6")) {
          nthBlock = canBePushed(blocks, sections[i], parentSection);
        }

        if (nthBlock?.length) {
          // pushHere();
          sections[i].htmlContent = nthBlock;
          blocksPushed = [...blocksPushed, nthBlock];
          continue;
        }
      }
      // Needs to be commented for responsiveness.,
      if (sections[i]?.outline?.includes("#00A5D6"))
        nthBlock = canBePushed(blocks, sections[i], parentSection);
      if (nthBlock.length) {
        if (!blocksPushed.includes(nthBlock)) {
          sections[i].htmlContent = nthBlock;
        }
      }
    }
  };
  const sortLayoutsLeftToRight = (layouts) => {
    //sorting Green layouts(columns) left to right
    layouts.sort((a, b) => parseFloat(a.left) - parseFloat(b.left));
  };

  //for flexDirection: Column
  const getalignItems = (child, index) => {
    if (Math.abs(600 - parseFloat(child.right) - parseFloat(child.left)) < 10)
      return "center";
    if (600 - parseFloat(child.right) > parseFloat(child.left)) {
      child.marginLeft = parseFloat(child.left) + "px";
      if (index === 0) return "flex-start";
    }
    if (600 - parseFloat(child.right) < parseFloat(child.left)) {
      child.marginRight = 600 - parseFloat(child.right) + "px";
      if (index === 0) return "flex-end";
    }
  };
  //for flexDirection: Column
  const getJustifyContent = (parentSection) => {
    if (
      Math.abs(
        parseFloat(parentSection.bottom) -
          parseFloat(parentSection.children[0].bottom) -
          parseFloat(parentSection.children[0].top)
      ) <
      parseFloat(parentSection.height) / 6
    )
      return "center";

    if (
      parseFloat(parentSection.bottom) -
        parseFloat(parentSection.children[0].bottom) >
      parseFloat(parentSection.children[0].top)
    ) {
      parentSection.children[0].marginTop =
        parseFloat(parentSection?.children?.[0]?.top) + "px";
      return "flex-start";
    }
    {
      parentSection.children[0].marginBottom =
        parseFloat(parentSection.height) -
        (parseFloat(parentSection?.children?.[0]?.top) +
          parseFloat(parentSection?.children?.[0]?.height)) +
        "px";
      return "flex-end";
    }
  };
  const getJustifyContentFrColumns = (parentSection) => {
    let firstChild = parentSection?.children?.[0];
    let lastChild =
      parentSection?.children?.[parentSection?.children?.length - 1];

    if (
      Math.abs(
        600 - parseFloat(lastChild?.right) - parseFloat(firstChild?.left)
      ) < 10
    )
      return "center";

    // if (600 - parseFloat(lastChild.right) > parseFloat(firstChild.left))
    //   return "flex-start";

    // return "flex-end";
  };
  const getMarginLRFrColumns = (child, parentSection, index) => {
    child.marginLeft =
      index === 0 && parentSection?.justifyContent !== "center"
        ? child.left
        : index !== 0 &&
          parseFloat(child?.left) -
            parseFloat(parentSection?.children?.[index - 1]?.LPWidth) +
            "px";
  };
  const getMarginTBFrColumns = (parentSection, child) => {
    child.marginTop = child?.top;
  };

  const handleImageWithText = (layoutImageWithText, section) => {
    layoutImageWithText.htmlContent = layoutImageWithText?.htmlContent?.filter(
      (content) => content.startsWith("<img")
    );
    layoutImageWithText.display = "flex";
    layoutImageWithText.flexDirection = "column";
    delete layoutImageWithText.position;
    handleOverlap(layoutImageWithText);
  };
  const addPAOnOverlap = (child, children, index, mtpNSkp) => {
    let prevChild = children[index - 1 - mtpNSkp.skip];
    if (parseFloat(prevChild?.bottom) > parseFloat(child?.bottom)) {
      prevChild.position = "absolute";
      prevChild.containerAbTop = prevChild?.initTop;
      prevChild.containerAbLeft = prevChild?.initLeft;
      delete prevChild?.marginLeft;
      delete prevChild?.marginTop;

      mtpNSkp.skip++;
      let imgAutoHeightAddn = 0;
      if (child.blocktype === "LAYOUT_FIGURE") {
        imgAutoHeightAddn = 0.03 * parseFloat(children[index - 1].height);
        // children[index - 1].height += imgAutoHeightAddn;
      }
      if (
        index === 1 ||
        children[index - 1 - mtpNSkp.skip]?.position === "absolute"
      ) {
        mtpNSkp.marginTop = child?.top;
      } else {
        mtpNSkp.marginTop =
          parseFloat(child?.top) -
          (parseFloat(children[index - 1 - mtpNSkp.skip]?.top) +
            parseFloat(children[index - 1 - mtpNSkp.skip]?.height) +
            imgAutoHeightAddn) +
          "px";
      }
      mtpNSkp.skip = 0;
    } else {
      child.position = "absolute";
      child.containerAbTop = child?.initTop;
      child.containerAbLeft = child?.initLeft;
      delete child?.marginLeft;
      delete child?.marginTop;
      mtpNSkp.skip++;
    }
  };
  const handleOverlap = (section, origin) => {
    debugger;
    console.log("handleOverlap");
    let children = section?.children;
    children?.sort((a, b) => parseFloat(a.top) - parseFloat(b.top));
    if (origin === "LIST") {
      children.textAlign = "start";
      children.paddingLeft = "2px";
    }
    let skip = 0;
    for (let index = 0; index < children?.length; index++) {
      let child = children[index];
      let marginTop = "";
      let imgAutoHeightAddn = 0;

      if (index === 0) marginTop = child?.top;
      else {
        if (child.blocktype === "LAYOUT_FIGURE") {
          imgAutoHeightAddn = 0.03 * parseFloat(children[index - 1]?.height);
          // children[index - 1].height += imgAutoHeightAddn;
        }
        if (index !== skip) {
          marginTop =
            parseFloat(child.top) -
            (parseFloat(children[index - skip - 1]?.top) +
              parseFloat(children[index - skip - 1]?.height) +
              imgAutoHeightAddn) +
            "px";
        } else {
          marginTop =
            parseFloat(child.top) -
            (parseFloat(children[index - 1]?.top) +
              parseFloat(children[index - 1]?.height) +
              imgAutoHeightAddn) +
            "px";
        }
      }

      if (origin === "LIST") {
        child.textAlign = "start";
        child.paddingLeft = "2px";
      }
      if (child.blocktype === "LAYOUT_LIST") {
        handleOverlap(child, "LIST"); //handleOverlap()
      }
      if (child.blocktype === "LAYOUT_FIGURE_WITH_TEXT") {
        handleImageWithText(child);
      }

      let mtpNSkp = { marginTop: marginTop, skip: skip };
      if (parseFloat(marginTop) < -2) {
        addPAOnOverlap(child, children, index, mtpNSkp);
        marginTop = mtpNSkp.marginTop;
        skip = mtpNSkp.skip;
      } else skip = 0;

      if (skip === 0) {
        delete child?.position;
        child.marginTop = marginTop;
        child.marginLeft = child?.left;
      }
    }
    section.children = children;
  };
  const rmvPAOfSingleChildOfRedL = (section) => {
    section.flexDirection = "column";
    section.justifyContent = getJustifyContent(section);
    section.alignItems = getalignItems(section?.children?.[0], 0);
    let singleChild = section?.children?.[0];
    delete singleChild?.position;
    if (singleChild.blocktype === "LAYOUT_FIGURE_WITH_TEXT")
      handleImageWithText(singleChild, section);
    if (singleChild.blocktype === "LAYOUT_LIST") {
      handleOverlap(singleChild, "LIST");
    }
  };
  const rmvPAOfMultipleChildrenOfRedL = (section) => {
    section.flexDirection = "column";
    handleOverlap(section);
  };
  const getColumnsWithMultiChildren = (
    columnsWithMultiChildren,
    totalColumns,
    section
  ) => {
    return [
      ...columnsWithMultiChildren,
      ...section?.children?.filter((child, index) => {
        if (child?.column === "multi") {
          //add flex property and remove PA from columns
          if (section?.justifyContent !== "space-between") {
            if (index === 0) {
              section.justifyContent = getJustifyContentFrColumns(section);
              section.flexWrap = "wrap";
            }
            getMarginLRFrColumns(child, section, index);
            getMarginTBFrColumns(section, child);
          }
          delete child?.position; //PAI
          child.display = "flex";
          child.flexDirection = "column";
          if (
            child?.children?.length === 1 &&
            child?.children?.[0]?.blocktype !== "LAYOUT_LIST"
          ) {
            // if the column has a single child && child is not LIST
            if (child?.children?.[0]?.blocktype === "LAYOUT_FIGURE_WITH_TEXT")
              handleImageWithText(child?.children?.[0], child);
            else {
              delete child?.children?.[0].position;
              child.children[0].display = "flex";
              child.children[0].flexDirection = "column";
              // section.alignItems = blocks[i].style.textAlign;
              if (child?.children?.[0]?.blocktype !== "LAYOUT_TEXT")
                child.children[0].alignItems = "center";
            }
          }
          totalColumns = [...totalColumns, child];
        }
        return (
          child.column === "multi" &&
          (child.children.length > 1 ||
            child.children[0].blocktype === "LAYOUT_LIST")
        );
      }),
    ];
  };
  const handlePARmvlForColumnsWithMultiChildren = (
    columnsWithMultiChildren
  ) => {
    // let irregularStructure = false;
    columnsWithMultiChildren.forEach((column) => {
      handleOverlap(column);
    });
  };
  const hndlePosnAbsRmvl = (sections) => {
    let columnsWithMultiChildren = [];
    let totalColumns = [];
    sections.forEach((section) => {
      //Remove pA for Red layout's blue children ---starts
      section.display = "flex";
      if (section?.children?.length === 1) {
        rmvPAOfSingleChildOfRedL(section);
      } else if (
        section?.children?.length > 1 &&
        !section?.children?.filter((child) => child?.column === "multi").length
      ) {
        rmvPAOfMultipleChildrenOfRedL(section);
      }
      //Remove pA for Red layout's blue children ---ends

      //for header and footer --starts
      // if (parentIndex === 0 || parentIndex === sections.length - 1) {
      //   if (
      //     section.children.length > 4 &&
      //     section.children.every((child) => {
      //       if (child.column === "multi") child.justifyContent = "center";
      //       return child.column === "multi";
      //     })
      //   ) {
      //     section.justifyContent = "space-between";
      //     section.flexWrap = "wrap";
      //   }
      // }
      //for header and footer --ends

      // ;
      columnsWithMultiChildren = getColumnsWithMultiChildren(
        columnsWithMultiChildren,
        totalColumns,
        section
      );
    });
    //columns having morethan one child
    handlePARmvlForColumnsWithMultiChildren(columnsWithMultiChildren);
  };
  const generateLayout = () => {
    // let families = [];blllhghghg
    // let children = [];
    var sectionsStructureInString = "";

    if (props.arrayOfBoxesDrawnRef.current.length) {
      if (props.genLayoutClicked) {
        // var divElement = HtMLStringToDOMElement(props.htmlRef.current);
        // let blocks = divElement.children[1].children[0].children;
        let blocks = Array.from(
          document.getElementsByClassName("page")[0].childNodes
        );
        let sections = JSON.parse(
          JSON.stringify(props.arrayOfBoxesDrawnRef.current)
        );
        let columnObject = [];
        sections.forEach((obj, index) => {
          sections[index].name = "section" + index;
          if (obj.outline.includes("#24f52e")) {
            obj.column = "multi";
            columnObject.push(obj);
          }
          obj.originalBoxWidth = obj.originalBoxWidth;
          obj.blocktype = obj.blocktype;
        });
        //Commented for now due to 2 column issues faced by MJML
        // if (fileContext.file.application_type === APPLICATION_TYPE_EDETAIL) {
        //   sections = sections.filter((section) =>
        //     section.outline.includes("#00A5D6")
        //   );
        // }
        sections = sections.filter(
          (section) => !section.outline.includes("black")
        );
        let exit = false;

        // sections.sort((a, b) => parseFloat(a.top) - parseFloat(b.top)); // added -3 to add threshold of 3px to innerlayout tops when there is overlap
        // buildHierarchy(sections); // sections are passed /parallel

        const allSections = sections.reduce(
          (pv, cv, i) => {
            if (cv.outline.includes("#ef9a9a")) {
              pv.outerSection.push(cv);
            } else if (cv.outline.includes("#24f52e")) {
              pv.greenColumn.push(cv);
            } else {
              pv.innerLayout.push(cv);
            }

            if (sections.length - 1 === i) {
              pv.outerSection.sort(
                (a, b) => parseFloat(a.top) - parseFloat(b.top)
              );
              pv.greenColumn.sort(
                (a, b) => parseFloat(a.top) - parseFloat(b.top)
              );
              pv.innerLayout.sort(
                (a, b) => parseFloat(a.top) - parseFloat(b.top)
              );
            }
            return pv;
          },
          { outerSection: [], greenColumn: [], innerLayout: [] }
        );
        allSections.innerLayout = buildInnerContentHierarchy(
          allSections.innerLayout
        );
        // buildInnerContentHierarchy(allSections.innerLayout);
        sections = buildHierarchy(allSections);

        if (fileContext.file.application_type !== APPLICATION_TYPE_EDETAIL)
          sections = sections.filter((section) =>
            section.outline.includes("#ef9a9a")
          );
        for (let k = 0; k < sections.length; k++) {
          let section = sections[k];
          let children = section?.children;
          if (children.length) {
            if (children?.length > 1 && children[0].column === "multi")
              sortLayoutsLeftToRight(children);
          }
        }

        sections.forEach((section, index) => {
          section?.children?.forEach((children) => {
            if (children.column === "multi") {
              if (!children?.children?.length) {
                if (!toast.isActive(toastId.current)) {
                  toast.error(
                    `Coulmn of outerSection ${
                      index + 1
                    } doesnot have inner layouts! `,
                    {
                      toastId: "files-search-error",
                      pauseOnFocusLoss: false,
                    }
                  );
                }
                exit = true;
              } else if (children?.children?.[0].column === "multi") {
                if (!toast.isActive(toastId.current)) {
                  toast.error(
                    ` columns of outerSection ${index + 1}  are nested!`,
                    {
                      toastId: "files-search-error",
                      pauseOnFocusLoss: false,
                    }
                  );
                }
                exit = true;
              }
            }
          });
        });
        if (exit) {
          props.genLayoutRef.current = false;
          props.setGenLayoutClicked(false);
          props.setIsGJSLoading(false);
          exit = true;
          return;
        }

        pushContentsInside(blocks, sections); //push content to respective sections
        if (fileContext.file.application_type === APPLICATION_TYPE_EDETAIL) {
          hndlePosnAbsRmvl(sections);
        }
        // Handle List outside column
        // And write modular function for the above code.
        let cloneSections = JSON.parse(JSON.stringify(sections));
        sectionsStructureInString = getDomElementFromObject(
          sections,
          cloneSections
        );
        // sectionsStructureInString = getGreenDivs();
      } else if (props.rhsHtmlForExportRef.current)
        sectionsStructureInString =
          props.rhsHtmlForExportRef.current || `<body></body>`;

      if (editor?.setComponents) {
        editor?.DomComponents?.getWrapper()?.set("content", "");

        editor?.setComponents(sectionsStructureInString);

        props.setIsGJSLoading(false);

        setTimeout(() => {
          const compEle = editor.getComponents().models;
          compEle.forEach((n) => {
            let innerCompEle = n.components().models;
            n.set({ droppable: false, draggable: false });
            innerCompEle.forEach((m) => {
              m.set({ droppable: false, draggable: false });
              let innerMostcomp = m.components().models;
              innerMostcomp.forEach((l) => {
                l.set({ droppable: false, draggable: false });
              });
            });
          });
        }, 500);
      }
      if (sectionsStructureInString.includes("draggable")) {
        props.setIsLayoutGenerated(true);
        if (props.genLayoutClicked) intermediateSaveCall();
      }
    } else {
      if (props.clearAllLayoutClicked) {
        editor?.DomComponents?.getWrapper()?.set("content", "");
        editor?.setComponents("<section></section>");
        props.setClearAllLayoutClicked(false);
        props.setIsGJSLoading(false);
      }
    }

    props.genLayoutRef.current = false;
    props.setGenLayoutClicked(false);
    // props.setIsGJSLoading(false);
  };

  const HtMLStringToDOMElement = (htmlString, parent) => {
    // Create a DOMParser

    const parser = new DOMParser();

    // Parse the HTML string into a DOM element
    const doc = parser.parseFromString(htmlString, "text/html");
    if (!doc.body.childNodes?.length) return;
    let styles = doc?.body?.firstChild?.style;

    let parentTop = parent?.initTop || parent.top;
    let parentLeft = parent.initLeft || parent.left;
    doc.body.firstChild.style.top =
      parseFloat(styles.top) - parseFloat(parentTop) + "px";
    doc.body.firstChild.style.left =
      parseFloat(styles.left) - parseFloat(parentLeft) + "px";
    // // Extract the html element from the parsed document
    return doc.body.firstChild;
  };

  const exportHTML = (editor, origin) => {
    // if (editor?.getComponents()) return false;
    // APPLICATION_TYPE_EDETAIL
    const htmlString = editor.getHtml();
    const cssContent = editor.getCss();
    var divElement = props.HtMLStringToDOMElementFromExport(htmlString);

    const cssStyles = props.cssStringToCSSStyles(cssContent);
    const HTMLWithStyle = props.replaceIdsWithStyles(divElement, cssStyles);
    // Now, divElement contains

    const blob = new Blob([`${divElement.outerHTML}`], {
      type: "text/html",
    });
    let htmlContent = divElement.outerHTML;
    let htmlContentWithWrapper = divElement.outerHTML;
    if (fileContext.file.application_type === APPLICATION_TYPE_EDETAIL) {
      const body = divElement.getElementsByTagName("body")[0];
      body.style.height = "100%";
      htmlContent = divElement.outerHTML;
      const wrapper = document.createElement("div");
      wrapper.id = "wrapper";
      wrapper.style =
        "min-width:1024px; min-height:768px; display:flex;flex-direction:column; ";
      while (body.firstChild) {
        wrapper.appendChild(body.firstChild);
      }
      body.appendChild(wrapper);
      htmlContentWithWrapper = divElement.outerHTML;
    }
    return {
      htmlContent: htmlContent,
      htmlContentWithWrapper: htmlContentWithWrapper,
    };
  };

  const dimensionsClicked = () => {
    if (isOptDisabled.current) return;
    const smDim = document.getElementsByClassName(
      "gjs-sm-sector__dimension"
    )[0];
    // if it is already open and user clicks on same options then hide it
    if (smDim.style.display === "block") {
      smDim.style.display = "none";
      setIsPopUp(false);
    } else {
      smDim.style.display = "block";
    }

    const sm = document.getElementsByClassName("gjs-sm-sector__typography")[0];
    sm.style.display = "none";

    const decorations = document.getElementsByClassName(
      "gjs-sm-sector__decorations"
    )[0];
    decorations.style.display = "none";
  };
  const typographyClicked = () => {
    const smDim = document.getElementsByClassName(
      "gjs-sm-sector__dimension"
    )[0];

    smDim.style.display = "none";
    const sm = document.getElementsByClassName("gjs-sm-sector__typography")[0];

    // if it is already open and user clicks on same options then hide it
    if (sm.style.display === "block") {
      sm.style.display = "none";
      setIsPopUp(false);
    } else {
      sm.style.display = "block";
    }

    const decorations = document.getElementsByClassName(
      "gjs-sm-sector__decorations"
    )[0];
    decorations.style.display = "none";
  };
  const appearanceClicked = () => {
    const smDim = document.getElementsByClassName(
      "gjs-sm-sector__dimension"
    )[0];
    smDim.style.display = "none";

    const sm = document.getElementsByClassName("gjs-sm-sector__typography")[0];
    sm.style.display = "none";

    const decorations = document.getElementsByClassName(
      "gjs-sm-sector__decorations"
    )[0];

    // if it is already open and user clicks on same options then hide it
    if (decorations.style.display === "block") {
      decorations.style.display = "none";
      setIsPopUp(false);
    } else {
      decorations.style.display = "block";
    }
  };

  // Example: Export button click event
  // document.getElementById("exportButton").addEventListener("click", exportHTML);

  return (
    <div id="editor" className="editor">
      <div className="gjsTopBar">
        User generated
        {"       "}
      </div>
      {/*<div className="buttonsContainer">
        <button className="customButtons" onClick={exportHTML}>
          Export HTML
        </button>
  </div>*/}
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div id="gjs"></div>
        {/*<div id="blocks"></div>*/}
        <div className="sidebarDivGrapesjs">
          <div className="IconDiv">
            <IconButton
              onClick={() => {
                setIsPopUp(true);
                // setPopupName("Dimension");
                dimensionsClicked();
              }}
              size="small"
              color="inherit"
            >
              <img src={require("../../Images/dimensions.png")} alt="logo" />
              <div className="sideLabel">Dimension</div>
            </IconButton>
          </div>
          <div className="IconDiv">
            <IconButton
              onClick={() => {
                setIsPopUp(true);
                // setPopupName("Typography");
                typographyClicked();
              }}
              size="small"
              color="inherit"
            >
              <img src={require("../../Images/typography.png")} alt="logo" />
              <div className="sideLabel">Typography</div>
            </IconButton>
          </div>
          <div className="IconDiv">
            <IconButton
              onClick={() => {
                setIsPopUp(true);
                // setPopupName("Appearance");
                appearanceClicked();
              }}
              size="small"
              color="inherit"
            >
              <img src={require("../../Images/appearance.png")} alt="logo" />
              <div className="sideLabel">Appearance</div>
            </IconButton>
          </div>
          {/* <div className="IconDiv">
            <IconButton
              onClick={() => {
                setIsPopUp(true);
                setPopupName("Effects");
              }}
              size="small"
              color="inherit"
            >
              <img src={require("../../Images/effect.png")} alt="logo" />
              <div className="sideLabel">Effects</div>
            </IconButton>
          </div> */}

          {/* {isPopUp && (
            <div className="grapesjs-editor-popover">
              <div id="stylesMangerBlock" className="stylesMangerBlock"></div>
            </div>
          )} */}

          <div
            className="grapesjs-editor-popover"
            style={{ visibility: isPopUp ? "visible" : "hidden" }}
          >
            <div id="stylesMangerBlock" className="stylesMangerBlock"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GrapejsEditor;
